import { Col, Container, Image, Row, Form } from "react-bootstrap";
import { useState } from "react";
import { localAuthenticate } from "../services/user-master-service";
import LoginImage from "../images/LoginImage.png";
import Logo from "../images/Godrej_Precision_Logo.svg";

export default function LoginLocalAuth({ setIsAuthenticated }) {
  const [userName, setUserName] = useState("");
  function handleLogin() {
    localAuthenticate(userName).then((resp) => {
      if (resp) {
        sessionStorage.setItem("Token", resp.Token);
        sessionStorage.setItem("UserData", JSON.stringify(resp.UserData));
        alert("Login successful");
        setIsAuthenticated(true);
      } else {
        alert("Please check your username and password");
      }
    });
  }

  return (
    <Container className="login-container">
      <Row className="login-width">
        <Col md={8} className="login-container-left">
          <Image src={LoginImage} alt="Login_Image" />
        </Col>
        <Col
          md={4}
          className="d-flex flex-column justify-content-center min-vh-100 login-container-right"
        >
          <div>
            <Image src={Logo} alt="Logo" />
          </div>
          <h2 className="app-name">
            Online Design
            <br />
            Release System
            <br />
          </h2>
          <div className="field">
            <Form>
              <Form.Label htmlFor="username">Username</Form.Label>
              <input
                className="input"
                name="username"
                type="text"
                placeholder="Username"
                id="username"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </Form>
          </div>
          <div className="login-btn pink-btn" onClick={handleLogin}>
            <span>Login</span>
          </div>
          {/* <div className="brand mt-auto">
            Ideated By Godrej Construction
            <br /> Designed And Developed By Godrej Infotech
          </div> */}
        </Col>
      </Row>
    </Container>
  );
}
